.table {
  width: 100%;
  border-spacing: 0;
}
.header_cell {
  color: #717171;
  font-size: 13px;
  font-weight: normal;
  padding: 0 20px 20px;
  border-bottom: 1px solid #e0e0e0;
}
.loading {
  position: relative;
}
.loading:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.7);
  z-index: 1;
}
.loading:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  background: url("../../../styles/img/spin.svg");
  width: 20px;
  height: 20px;
  margin-left: -10px;
  text-outline: -10px;
  z-index: 2;
}
.cell {
  height: 93px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  color: #333;
  padding: 0 20px;
}
.cell:first-child {
  border-left: 1px solid #e0e0e0;
}
.cell:last-child {
  border-right: 1px solid #e0e0e0;
}
.condensed {
  height: 50px;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
