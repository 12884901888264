.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  z-index: 1000;
}
.wrapper:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(0,0,0,0.5);
  cursor: pointer;
}
.content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: auto;
  z-index: 1;
}
.content:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.popup_wrapper {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  text-align: left;
  overflow: auto;
  white-space: normal;
}
.popup {
  background: #fff;
  padding: 19px 15px;
  width: 950px;
}
.close {
  position: absolute;
  right: 15px;
  top: 30px;
  cursor: pointer;
}
.close_icon {
  display: block;
  width: 15px;
  height: 17px;
  stroke: #717171;
  transition: all 0.1s ease-out;
}
.close_icon:hover {
  stroke: #333;
}
.title {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 46px;
  margin-right: 15px;
}
.footer {
  font-size: 20px;
  margin-top: 40px;
}
.total {
  display: inline-block;
  vertical-align: middle;
}
.bold {
  font-weight: bold;
}
.button {
  margin-left: 10px;
}
.total_inner {
  display: inline-block;
  min-width: 270px;
}
.loading {
  display: block;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  background: url("../../../styles/img/spin.svg");
  width: 20px;
  height: 20px;
}
